<template>

<div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 pb-3" id="leadstable">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6" style="opacity:0.8">
                  Total Contacts: {{ pagination.total }} entries
                </div>
              </div>     
          </div>
                         <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap tab-table">
                            <el-table :data="queriedData" @sort-change="sortdynamic" ref="LeadtableData" :default-sort="{prop: 'clickdate', order: 'descending'}">
                                    <template #empty v-if="!tableNoData">
                                          <i class="fas fa-spinner fa-pulse fa-2x d-block"></i>Loading data...
                                    </template>
                                    <el-table-column prop="full_name" min-width="180" sortable="custom"  label="Full Name">
                                        <div slot-scope="props">
                                              <span v-if="showfirstname">{{props.row.firstname}}</span>  <span v-if="showlastname">{{props.row.lastname}}</span>
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="240" label="Contact">
                                        <div slot-scope="props">
                                              <i class="fas fa-envelope"></i>&nbsp;&nbsp;{{props.row.email}}<br>
                                              <i class="fas fa-phone" v-if="showphone"></i><span v-if="showphone">&nbsp;&nbsp;{{props.row.phone}}</span>
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="240" label="Keyword/URL">
                                        <div slot-scope="props">
                                              <i class="fas fa-search" ></i><span>&nbsp;&nbsp;{{props.row.keyword}}</span>
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="280" align="center" label="Address" v-if="showaddress">
                                        <div slot-scope="props">
                                              <span v-if="showaddress">{{props.row.address1}}</span> {{props.row.city}}, {{props.row.state}} {{props.row.zipcode}}
                                        </div>
                                    </el-table-column>
                                    <!--<el-table-column sortable :sort-method="sortcolumn"  min-width="15%" align="center" label="Source">
                                        <div slot-scope="props">
                                              {{props.row.source}}
                                        </div>
                                    </el-table-column>
                                    <el-table-column sortable :sort-method="sortcolumn"  min-width="20%" align="center" label="Referer">
                                        <div slot-scope="props">
                                              {{props.row.referer}}
                                        </div>
                                    </el-table-column>-->
                                    <el-table-column prop="clickdate" sortable :sort-method="sortdate"  min-width="130" align="center" label="Click Date">
                                        <div slot-scope="props">
                                               {{format_date(props.row.clickdate)}}
                                        </div>
                                    </el-table-column>

                                    <template v-if="moduleType == 'enhance'">
                                      <!-- <el-table-column v-for="(item, index) in personAdvanceField" :key="index" min-width="280" align="center" :label="personAdvanceLabel[index]">
                                          <div slot-scope="props">
                                            <span>{{props.row[item]}}</span>
                                          </div>
                                      </el-table-column> -->
                                      <el-table-column min-width="280" align="center" label="Gender">
                                          <div slot-scope="props">
                                            <span>{{props.row.gender_aux}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Generation">
                                          <div slot-scope="props">
                                            <span>{{props.row.generation}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Marital Status">
                                          <div slot-scope="props">
                                            <span>{{props.row.marital_status}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Phone 3">
                                          <div slot-scope="props">
                                            <span>{{props.row.mobile_phone_3}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Tax Bill Mailing Information">
                                          <div slot-scope="props">
                                            <span>{{props.row.tax_bill_mailing_address}} {{ props.row.tax_bill_mailing_city }} {{ props.row.tax_bill_mailing_county }} {{ props.row.tax_bill_mailing_state }} {{ props.row.tax_bill_mailing_zip }}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Dwelling Type">
                                          <div slot-scope="props">
                                            <span>{{props.row.dwelling_type}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Home Owner">
                                          <div slot-scope="props">
                                            <span>{{props.row.home_owner}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Home Owner Ordinal">
                                          <div slot-scope="props">
                                            <span>{{props.row.home_owner_ordinal}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Length of Residence">
                                          <div slot-scope="props">
                                            <span>{{props.row.length_of_residence}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Home Price">
                                          <div slot-scope="props">
                                            <span>{{props.row.home_price}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Home Value">
                                          <div slot-scope="props">
                                            <span>{{props.row.home_value}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Median Home Value">
                                          <div slot-scope="props">
                                            <span>{{props.row.median_home_value}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Living Sqft">
                                          <div slot-scope="props">
                                            <span>{{props.row.living_sqft}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Year Built Original">
                                          <div slot-scope="props">
                                            <span>{{props.row.yr_built_orig}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Year Built Range">
                                          <div slot-scope="props">
                                            <span>{{props.row.yr_built_range}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Lot Number">
                                          <div slot-scope="props">
                                            <span>{{props.row.lot_number}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Legal Description">
                                          <div slot-scope="props">
                                            <span>{{props.row.legal_description}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Land Sqft">
                                          <div slot-scope="props">
                                            <span>{{props.row.land_sqft}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Garage Sqft">
                                          <div slot-scope="props">
                                            <span>{{props.row.garage_sqft}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Subdivision">
                                          <div slot-scope="props">
                                            <span>{{props.row.subdivision}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Zoning Code">
                                          <div slot-scope="props">
                                            <span>{{props.row.zoning_code}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Household Income">
                                          <div slot-scope="props">
                                            <span>{{props.row.income_household}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Median Household Income">
                                          <div slot-scope="props">
                                            <span>{{props.row.income_midpts_household}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Household Net Worth">
                                          <div slot-scope="props">
                                            <span>{{props.row.net_worth_household}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Median Household Net Worth">
                                          <div slot-scope="props">
                                            <span>{{props.row.net_worth_midpt_household}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Discretionary Income">
                                          <div slot-scope="props">
                                            <span>{{props.row.discretionary_income}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Credit Score Median">
                                          <div slot-scope="props">
                                            <span>{{props.row.credit_midpts}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Credit Score Range">
                                          <div slot-scope="props">
                                            <span>{{props.row.credit_range}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Number of Adults in Household">
                                          <div slot-scope="props">
                                            <span>{{props.row.num_adults_household}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Number of Children in Household">
                                          <div slot-scope="props">
                                            <span>{{props.row.num_children_household}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Number of People in Household">
                                          <div slot-scope="props">
                                            <span>{{props.row.num_persons_household}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Children Aged 0-3 in Household">
                                          <div slot-scope="props">
                                            <span>{{props.row.child_aged_0_3_household}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Children Aged 4-6 in Household">
                                          <div slot-scope="props">
                                            <span>{{props.row.child_aged_4_6_household}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Children Aged 7-9 in Household">
                                          <div slot-scope="props">
                                            <span>{{props.row.child_aged_7_9_household}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Children Aged 10-12 in Household">
                                          <div slot-scope="props">
                                            <span>{{props.row.child_aged_10_12_household}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Children Aged 13-18 in Household">
                                          <div slot-scope="props">
                                            <span>{{props.row.child_aged_13_18_household}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Children in Household">
                                          <div slot-scope="props">
                                            <span>{{props.row.children_household}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Cooking">
                                          <div slot-scope="props">
                                            <span>{{props.row.cooking}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Gardening">
                                          <div slot-scope="props">
                                            <span>{{props.row.gardening}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Music">
                                          <div slot-scope="props">
                                            <span>{{props.row.music}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="DIY">
                                          <div slot-scope="props">
                                            <span>{{props.row.diy}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Books">
                                          <div slot-scope="props">
                                            <span>{{props.row.books}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Travel Vacation">
                                          <div slot-scope="props">
                                            <span>{{props.row.travel_vacation}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Health Beauty Products">
                                          <div slot-scope="props">
                                            <span>{{props.row.health_beauty_products}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Pet Owner">
                                          <div slot-scope="props">
                                            <span>{{props.row.pet_owner}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Photography">
                                          <div slot-scope="props">
                                            <span>{{props.row.photography}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Fitness">
                                          <div slot-scope="props">
                                            <span>{{props.row.fitness}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Epicurean">
                                          <div slot-scope="props">
                                            <span>{{props.row.epicurean}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Occupation Category">
                                          <div slot-scope="props">
                                            <span>{{props.row.occupation_category}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Occupation Type">
                                          <div slot-scope="props">
                                            <span>{{props.row.occupation_type}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Occupation Detail">
                                          <div slot-scope="props">
                                            <span>{{props.row.occupation_detail}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Magazine Subscriber">
                                          <div slot-scope="props">
                                            <span>{{props.row.magazine_subscriber}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Charity Interest">
                                          <div slot-scope="props">
                                            <span>{{props.row.charity_interest}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Likely Charitable Donor">
                                          <div slot-scope="props">
                                            <span>{{props.row.likely_charitable_donor}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Cbsa">
                                          <div slot-scope="props">
                                            <span>{{props.row.cbsa}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Census Block">
                                          <div slot-scope="props">
                                            <span>{{props.row.census_block}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Census Block Group">
                                          <div slot-scope="props">
                                            <span>{{props.row.census_block_group}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Census Tract">
                                          <div slot-scope="props">
                                            <span>{{props.row.census_tract}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Voter">
                                          <div slot-scope="props">
                                            <span>{{props.row.voter}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Urbanicity">
                                          <div slot-scope="props">
                                            <span>{{props.row.urbanicity}}</span>
                                          </div>
                                      </el-table-column>

                                    </template>

                                    <template v-if="moduleType == 'b2b'">
                                      <el-table-column min-width="280" align="center" label="Employee ID">
                                          <div slot-scope="props">
                                            <span>{{props.row.employee_id}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Company Name">
                                          <div slot-scope="props">
                                            <span>{{props.row.company_name}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column v-if="showphone" min-width="280" align="center" label="Company Phone">
                                          <div slot-scope="props">
                                            <span>{{props.row.phone_1_company}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Company Website">
                                          <div slot-scope="props">
                                            <span>{{props.row.company_website}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Num Employees">
                                          <div slot-scope="props">
                                            <span>{{props.row.num_employees}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Sales Volume">
                                          <div slot-scope="props">
                                            <span>{{props.row.sales_volume}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Year Founded">
                                          <div slot-scope="props">
                                            <span>{{props.row.year_founded}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Job Title">
                                          <div slot-scope="props">
                                            <span>{{props.row.job_title}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Level">
                                          <div slot-scope="props">
                                            <span>{{props.row.level}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Job Function">
                                          <div slot-scope="props">
                                            <span>{{props.row.job_function}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="Headquarters Branch">
                                          <div slot-scope="props">
                                            <span>{{props.row.headquarters_branch}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="NAICS CODE">
                                          <div slot-scope="props">
                                            <span>{{props.row.naics_code}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="LastSeenDate">
                                          <div slot-scope="props">
                                            <span>{{props.row.last_seen_date}}</span>
                                          </div>
                                      </el-table-column>
                                      <el-table-column min-width="280" align="center" label="LinkedIn">
                                          <div slot-scope="props">
                                            <span>{{props.row.linked_in}}</span>
                                          </div>
                                      </el-table-column>
                                    </template>
                            </el-table>
                          
                         </div>

      <div class="col-sm-12 col-md-12 col-lg-12" style="margin-top: 2rem;">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6 ">
                   <base-pagination
                      class="pagination-no-border pull-right"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="pagination.total"
                      @input="changePage"
                      scrollToName="leadstable"
                    >
                    </base-pagination>
                </div>
              </div>
              
      </div>
           

      </div>
  
</template>

<script>

import {Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import moment from 'moment'

export default {
  props: {
    tableData:{},
    pagination: {},
    GetLeadsData: {
      type: Function,
    },
    showphone: {
      type: Boolean,
      default: false
    },
    showaddress: {
      type: Boolean,
      default: true
    },
    showfirstname: {
      type: Boolean,
      default: true
    },
    showlastname: {
      type: Boolean,
      default: true
    },
    tableNoData: {
      type: Boolean,
    },
    moduleType: {
      type: String
    }
  },
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      searchQuery: '',
      propsToSearch: ['name', 'email', 'age'],
      //tableData: [],
      searchedData: [],
      fuseSearch: null,
      currSortBy: 'clickdate',
      currOrderBy: 'descending'
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      
      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  methods: {
    sortcolumn: function(a,b) {
        return a.value - b.value;
    },
    sortdate: function(a,b) {
        return new Date(a.clickdate) - new Date(b.clickdate);
    },
      sortnumber: function(a,b) {
          return a - b;
    },
    format_date(value){
         if (value) {
           return moment(String(value)).format('MM-DD-YYYY')
          }
      },
    changePage(event){
        this.GetLeadsData(event, this.currSortBy, this.currOrderBy)
      },
    sortdynamic(column) {
      this.currSortBy = column.prop;
      this.currOrderBy = column.order;
      this.GetLeadsData(this.pagination.currentPage, column.prop, column.order)
      },
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.1
    });
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  },

};
</script>
<style></style>
